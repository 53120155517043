.myClass
{
color:black;
}

.emoji-happy {
	content: "\01F604";	
      }

.emoji-sad{
	content: "\01F61E", 
}
.swal-wide{
	grid-column: 2;
	grid-row: 2;
	align-self: center;
	justify-self: center;
    }